// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container div {
    float: left;
    margin-bottom: 15px;
}
.col-1-2 {
    width: 50%;
}
.col-1-3 {
    width: 33.3333333333%;
}
.col-1-4 {
    width: 25%;
}
.col-2-3 {
    width: 66.6666666667%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/About.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,UAAU;AACd;AACA;IACI,qBAAqB;AACzB;AACA;IACI,UAAU;AACd;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".container div {\n    float: left;\n    margin-bottom: 15px;\n}\n.col-1-2 {\n    width: 50%;\n}\n.col-1-3 {\n    width: 33.3333333333%;\n}\n.col-1-4 {\n    width: 25%;\n}\n.col-2-3 {\n    width: 66.6666666667%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
