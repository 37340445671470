// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Aleo_v2.0/otf/Aleo-ExtraBold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: Dzimaa, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Dzimaa, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* https://alessiolaiso.com/aleo-font */
@font-face {
  font-family: 'Aleo';
  src: local('Astro'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
  font-weight: 900;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;YAEU;EACV,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;WACS;AACX;;AAEA,uCAAuC;AACvC;EACE,mBAAmB;EACnB,+EAAqF;EACrF,gBAAgB;AAClB","sourcesContent":["body {\n  margin: 0;\n  font-family: Dzimaa, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n  sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: Dzimaa, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n  monospace;\n}\n\n/* https://alessiolaiso.com/aleo-font */\n@font-face {\n  font-family: 'Aleo';\n  src: local('Astro'), url(./fonts/Aleo_v2.0/otf/Aleo-ExtraBold.otf) format('opentype');\n  font-weight: 900;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
