import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signup } from "../Modules/Web-server_queries";
import "./Login.css";
import Alert from "../Modules/Alert";

function Signup() {
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [confirmation, setConfirmation] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        company: '',
        password: '',
        password2: ''
    });

    useEffect(() => {
        const storedAccessToken = localStorage.getItem('accessToken');
        const storedRefreshToken = localStorage.getItem('refreshToken');

        if (storedAccessToken && storedRefreshToken) {
            navigate('/LK');
        }
    }, [navigate]);

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /[0-9]/.test(password);

        if (password.length < minLength) {
            return `Пароль должен содержать минимум ${minLength} символов.`;
        }
        if (!hasUpperCase) {
            return 'Пароль должен содержать хотя бы одну заглавную букву.';
        }
        if (!hasLowerCase) {
            return 'Пароль должен содержать хотя бы одну строчную букву.';
        }
        if (!hasNumbers) {
            return 'Пароль должен содержать хотя бы одну цифру.';
        }

        return '';
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const passwordValidationMessage = validatePassword(formData.password);
        if (passwordValidationMessage) {
            setErrorMessage(passwordValidationMessage);
            setOpen(true);
            return;
        }

        if (formData.password === formData.password2) {
            try {
                const responseData = await signup(formData.email, formData.password, formData.company);
                const URLFromServer = responseData.url;
                //console.log(URLFromServer) Подтверждение почты
                setConfirmation(URLFromServer);
                navigate('/go_to_confirm');
            } catch (error) {
                let message;
                if (error.response) {
                    switch (error.response.status) {
                        case 500:
                            message = 'Что-то пошло не так. Попробуйте позже или обратитесь в поддержку.';
                            break;
                        case 400:
                            message = 'Аккаунт с такой почтой уже существует.';
                            break;
                        default:
                            message = 'Что-то пошло не так. Попробуйте перезагрузить страницу и попробовать еще раз.';
                            break;
                    }
                } else {
                    message = 'Что-то пошло не так. Попробуйте перезагрузить страницу и попробовать еще раз.';
                }
                setErrorMessage(message);
                setOpen(true);
            }
        } else {
            setErrorMessage('Пароли не совпадают');
            setOpen(true);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <div className="form-container">
            <h2 className="form-title">Регистрация</h2>
            <Alert mode={'error'} message={errorMessage} open={open} setOpen={setOpen} />
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="company">Компания:</label>
                    <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Пароль:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password2">Повторите пароль:</label>
                    <input
                        type="password"
                        id="password2"
                        name="password2"
                        value={formData.password2}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <button type="submit">Зарегистрироваться</button>
            </form>
        </div>
    );
}

export default Signup;
