import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ progress }) => {
    // Округляем progress до целого числа
    const roundedProgress = Math.round(progress);

    return (
        <div className="progress-bar-container">
            <div className="progress-bar-background">
                <div
                    className="progress-bar-fill"
                    style={{
                        width: `${roundedProgress}%`,
                        backgroundColor: roundedProgress === 100 ? '#4caf50' : '#4a8751'
                    }}
                />
                <div className="progress-bar-text">{roundedProgress}%</div>
            </div>
        </div>
    );
};

export default ProgressBar;
