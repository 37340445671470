import React from 'react';
import ReactPlayer from 'react-player';

const VideoPreview = ({ url, homepage }) => {
    const handleError = (e) => {
        console.error("Ошибка загрузки плеера: ", e);
    };

    return (
        <div className='player-wrapper' style={{ position: 'relative', paddingTop: '56.25%' }}>
            <ReactPlayer
                url={url}
                width='100%'
                height='100%'
                style={{ position: 'absolute', top: 0, left: 0 }}
                controls={!homepage}  // Показывать элементы управления только если homepage = false
                playing={true}   // Автоматическое воспроизведение если homepage = false
                loop={true}      // Повторное воспроизведение если homepage = false
                muted={homepage}      // Без звука если homepage = true
                onError={handleError} // Добавление обработки ошибки
            />
        </div>
    );
};

export default VideoPreview;
