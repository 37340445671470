import React from "react";
import VideoUpload from "../Modules/VideoUpload";

function Contact () {
    return (
        <div>
            <h2>Contact Page</h2>

        </div>
    );
}
export default Contact