import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Login.css";
import Alert from "../Modules/Alert";
import { login } from "../Modules/Web-server_queries";

function Login() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        // Check for tokens in local storage on app load
        const storedAccessToken = localStorage.getItem('accessToken');
        const storedRefreshToken = localStorage.getItem('refreshToken');

        if (storedAccessToken && storedRefreshToken) {
            navigate('/LK');
        }
    }, [navigate]);

    const handleSignUpClick = () => {
        navigate('/Signup');
    };

    const handleForgetPasswordClick = () => {
        navigate('/forgot');
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        login(formData.email, formData.password)
            .then(responseData => {
                localStorage.setItem('accessToken', responseData.access_token);
                localStorage.setItem('refreshToken', responseData.refresh_token);
                window.location.href = '/LK';
            })
            .catch(error => {
                if (error.response) {
                    switch (error.response.status) {
                        case 500:
                            setErrorMessage('Что-то пошло не так. Попробуйте позже или обратитесь в поддержку.');
                            console.error('InternalServerError');
                            break;
                        case 400:
                            setErrorMessage('Заполните все поля!');
                            console.error('SomeRequestArgumentsMissing');
                            break;
                        case 401:
                            setErrorMessage('Неверный логин или пароль. Проверьте правильность введенных данных.');
                            break;
                        default:
                            setErrorMessage('Что-то пошло не так. Попробуйте перезагрузить страницу и попробовать еще раз.');
                            console.error('Error:', error);
                            break;
                    }
                    setOpen(true);
                } else {
                    setErrorMessage('Что-то пошло не так. Попробуйте перезагрузить страницу и попробовать еще раз.')
                    setOpen(true);
                }
            });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <div className="form-container">
            <h2 className="form-title">Вход</h2>
            <Alert mode={'error'} message={errorMessage} open={open} setOpen={setOpen} />

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Пароль:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <button type="submit">Войти</button>
            </form>

            <div className="extra-buttons">
                <button onClick={handleForgetPasswordClick}>Забыли пароль?</button>
                <p>
                    <span className="inline-item">Еще нет аккаунта?</span>
                    <button className="signup-link" onClick={handleSignUpClick}>Создайте его!</button>
                </p>


            </div>
        </div>
    );
}

export default Login;
