import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {confirmAccess, refreshToken} from '../Modules/Web-server_queries';
import ProgressBar from "../Modules/ProgressBar";

import {getObjectFromS3, getSignedUrl, uploadObjectToS3} from '../Modules/s3Helper';
import Matrix from "../Modules/Matrix";
import {create_folders, get_results, get_status, predict, stop} from "../Modules/NN_queries";
import Alert from "../Modules/Alert";
import "./LK.css"
import ChartModule from "../Modules/Chart";
import VideoPreview from "../Modules/VideoPreview";
import VideoUpload from "../Modules/VideoUpload";

function LK() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [serverEmail, setServerEmail] = useState('');
    const [serverCompany, setServerCompany] = useState('');
    const [currentTask_id, setCurrentTask_id] = useState(0);

    const [status, setStatus] = useState();
    const [bar, setBar] = useState(0);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState();
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
    const [loadingMatrix, setLoadingMatrix] = useState(false);

    const [prevFiles, setPrevFiles] = useState([]);
    const [folder, setFolder] = useState([]);
    const [glLinks, setGlLinks] = useState([]);

    const [uploading, setUploading] = useState(false);

    const [infect, setInfect] = useState([]);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoDate, setVideoDate] = useState('');
    //const [videoDate, setVideoDate] = useState('');

    // Modal visibility state
    const [isModalOpen, setIsModalOpen] = useState(false);

    const storedAccessToken = localStorage.getItem('accessToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');

    const storedTask_id = parseInt(localStorage.getItem('Task_id'));

    function removeEmptyElements(array) {
        return array.filter(item => item !== '' && !(Array.isArray(item) && item.length === 0));
    }

    useEffect(() => {


// Генерация предустановленной ссылки

        if (!(storedAccessToken && storedRefreshToken)) {
            navigate('/login');
        }

        confirmAccess(storedAccessToken)
            .then(responseData => {
                setServerEmail(responseData.email);
                setServerCompany(responseData.company);
            })
            .catch(error => {
                let message;
                if (error.response) {
                    switch (error.response.status) {
                        case 403:
                            refreshToken(storedRefreshToken)
                                .then(responseData => {
                                    localStorage.setItem('accessToken', responseData.access_token);
                                    window.location.href = '/LK';
                                })
                                .catch(refreshError => {
                                    if (refreshError.response) {
                                        switch (refreshError.response.status) {
                                            case 500:
                                                message = 'Что-то пошло не так. Попробуйте позже или обратитесь в поддержку.';
                                                break;
                                            case 401:
                                                message = 'Пропущен заголовок авторизации. Обратитесь в поддержку.';
                                                break;
                                            case 422:
                                                message = 'Проблема со входом. Вы будете перенаправлены на страницу входа';
                                                break;
                                            default:
                                                message = 'Что-то пошло не так. Попробуйте перезагрузить страницу и попробовать еще раз.';
                                                break;
                                        }
                                    } else {
                                        message = 'Что-то пошло не так. Попробуйте перезагрузить страницу и попробовать еще раз.';
                                    }
                                });
                            break;
                        case 500:
                            message = 'Что-то пошло не так. Попробуйте позже или обратитесь в поддержку.';
                            break;
                        case 400:
                            message = 'Отсутствуют необходимые внутренние данные.';
                            break;
                        default:
                            message = 'Что-то пошло не так. Попробуйте перезагрузить страницу и попробовать еще раз.';
                            break;
                    }
                } else {
                    message = 'Что-то пошло не так. Попробуйте перезагрузить страницу и попробовать еще раз.';
                }
                setErrorMessage(message);
                setOpen(true);
                setTimeout(() => {
                    window.location.href = '/logout';
                }, 5000);

            });
        if (storedTask_id) {
            setCurrentTask_id(storedTask_id)
            setLoading(true)
        }

        get_results(storedAccessToken)
            .then(result => {
                setPrevFiles(removeEmptyElements(result.files))
                setCurrentVideoIndex((removeEmptyElements(result.files)).length - 1)
            })
            .catch(error => {
                setErrorMessage(`Ошибка при получении истории результатов анализа, или вы еще не загружали ни одного видео!: ${error}`);
                setOpen(true)
            })

    }, [navigate, storedAccessToken, storedRefreshToken]);

    useEffect(() => {
        if (glLinks.length > 0 && folder.length > 0) {
            const links_dict = {
                src: glLinks,
                dst: folder
            };
            predict(storedAccessToken, links_dict)
                .then(result => {
                    setCurrentTask_id(result.task_id);
                    localStorage.setItem('Task_id', (result.task_id).toString())
                })
                .catch(error => {
                    setErrorMessage(`Ошибка при обработке видео: ${error}`);
                    setOpen(true)
                });

        }
    }, [folder]);

    useEffect(() => {
        if (storedTask_id) {
            setCurrentTask_id(storedTask_id)
            setLoading(true)
        }
        //console.log('task - ', currentTask_id)
        //console.log('bar - ', bar)
        if (currentTask_id !== 0) {
            get_status(storedAccessToken, currentTask_id)
                .then(result => {
                    setBar(result.progress);
                    setStatus(result.status);
                    setLoading(true)
                })
                .catch(error => {

                    setErrorMessage(`Ошибка при получении статуса обработки: ${error}`);
                    setOpen(true)
                });
        }
    }, [currentTask_id, storedTask_id]);

    useEffect(() => {

        if ((status === "queue" || status === "run" ||status === "complete") && loading && currentTask_id) {
            const interval = setInterval(() => {
                get_status(storedAccessToken, currentTask_id)
                    .then(result => {
                        setBar(result.progress);
                        setStatus(result.status);

                    })
                    .catch(error => {
                        setErrorMessage(`Ошибка при получении статуса обработки: ${error}`);
                        setOpen(true)
                    });
                if (status === "complete") {
                    clearInterval(interval);
                    setLoading(false);
                    setCurrentTask_id(0);
                    localStorage.removeItem('Task_id')
                    setBar(0);
                    setStatus('');
                    setIsProgressModalOpen(false)
                    get_results(storedAccessToken)
                        .then(result => {
                            setPrevFiles(removeEmptyElements(result.files))
                            setCurrentVideoIndex((removeEmptyElements(result.files)).length - 1)
                        })
                        .catch(error => {
                            setErrorMessage(`Ошибка при получении истории результатов анализа: ${error}`);
                            setOpen(true)
                        })

                    window.location.href = '/lk';

                }
                else if (status==='error')
                {
                    clearInterval(interval);
                    setLoading(false);
                    setCurrentTask_id(0);
                    localStorage.removeItem('Task_id')
                    setBar(0);
                    setStatus('');
                    setIsProgressModalOpen(false)
                    setErrorMessage(`Ошибка при формировании ответа, попробуйте еще раз или обратитесь в поддержку!`);
                    setOpen(true)
                }

            }, 1000);
        }

    }, [status]);

    useEffect(() => {

        if (prevFiles.length > 0 && currentVideoIndex !== undefined) {

            const file = prevFiles[currentVideoIndex];

            const parts = file.split('/');
            const bucket = parts.slice(0, -1).join('/');
            const key = parts[parts.length - 1];
            const datePart = parts.find(part => /\d{2}_\d{2}_\d{2}/.test(part));
            const date = datePart ? datePart.replace(/_/g, '.') : 'Unknown Date';
            setVideoDate(date);
            getObjectFromS3('data-test/25/17_08_24/1723907473.878836','0_dst.mp4')
                .then(result=>{
                    console.log(result.data)
                })
            getObjectFromS3(bucket, key)
                .then(result => {
                    const jsonData = JSON.parse(result.data.Body.toString('utf-8'));
                    //setVideoS3Url(jsonData.dst);
                    setChartData(jsonData.num_detected);


                    getSignedUrl(jsonData.dst)
                        .then(result=>{
                            setVideoUrl(result)
                            //console.log(result)
                        })
                        .catch(error=>{
                            setErrorMessage(`Ошибка при получении видео: ${error}`);
                            setOpen(true);
                        })


                    fetchImages(jsonData.source_of_infection);
                })
                .catch(error => {
                    setErrorMessage(`Ошибка при получении изображений: ${error}`);
                    setOpen(true);
                });
        }
    }, [currentVideoIndex, prevFiles]);

    const fetchImages = async (inf) => {
        try {
            const images = await Promise.all(
                inf.map(async (item) => {

                    const parts = item[0].split('/');
                    const bucket = parts.slice(0, -1).join('/');
                    const key = parts[parts.length - 1];

                    const response = await getObjectFromS3(bucket, key);
                    const blob = new Blob([response.data.Body], {type: 'image/png'});
                    const imageUrl = URL.createObjectURL(blob);
                    return [imageUrl, item[1]];
                })
            );
            setInfect(images);
        } catch (error) {
            setErrorMessage(`Ошибка при получении изображений: ${error}`);
            setOpen(true);
        }
    };

    const handlePrev = () => {
        if (currentVideoIndex > 0) {
            setCurrentVideoIndex(currentVideoIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentVideoIndex < prevFiles.length - 1) {
            setCurrentVideoIndex(currentVideoIndex + 1);
        }
    };


    const handleUpload = async (uploadedFiles) => {
        try {
            setUploading(true);
            const folderCreation = await create_folders(storedAccessToken, uploadedFiles.length);
            const folders = folderCreation.folders;

            const links = [];
            // Upload each file to its corresponding folder
            for (let i = 0; i < folders.length; i++) {
                const file = uploadedFiles[i];
                links.push(`${folders[i]}/${file.name}`);
                await uploadObjectToS3(folders[i], file.name, file);
            }
            setGlLinks(links);
            setFolder(folders);
            setIsProgressModalOpen(true)
            setIsModalOpen(false);
            setUploading(false);  // Останавливаем спиннер после завершения загрузки
        } catch (error) {
            setErrorMessage(`Ошибка загрузки файлов: ${error}`);
            setOpen(true);
            setUploading(false);  // Останавливаем спиннер в случае ошибки
        }
    };

    const handleStop = () => {
        stop(storedAccessToken, currentTask_id)
            .then(() => {
                setLoading(false);
                setIsProgressModalOpen(false);
                setCurrentTask_id(0);
                setBar(0);
                setStatus('');
                localStorage.removeItem('Task_id');
                window.location.href = '/lk';
            })
            .catch(error => {
                setErrorMessage(`Ошибка при остановке обработки: ${error}`);
                setOpen(true);
            });
    };

    return (
        <div className="main-content">
            <Alert mode={'error'} message={errorMessage} open={open} setOpen={setOpen}/>

            <div className="content-wrapper">
                <div className="left-section">
                    <div className="navigation">
                        {currentVideoIndex > 0 && (
                            <button onClick={handlePrev}>{"<"}</button>
                        )}

                        <span>{`Video ${currentVideoIndex + 1}.mp4 - ${videoDate}`}</span>

                        {currentVideoIndex < prevFiles.length - 1 && (
                            <button onClick={handleNext}>{">"}</button>
                        )}
                    </div>
                    {infect.length > 0 && (
                        <>
                            <VideoPreview url={videoUrl} homepage={false}/>
                            <div className="chart-section">
                                <ChartModule data={chartData}/>
                            </div>
                        </>
                    )}
                </div>
                <div className="right-section">
                    <p>Аккаунт: <b>{serverEmail}</b> ; <b>"{serverCompany}"</b></p>
                    {loading ? (
                            <button onClick={() => setIsProgressModalOpen(true)}>Просмотр прогресса загрузки</button>
                        ) :
                        (
                            <button onClick={() => setIsModalOpen(true)} className="add-button">+</button>
                        )}
                    {infect.length > 0 && (
                        loadingMatrix ? (
                                <div className="spinner-container"> {/* Spinner container */}
                                    <div className="spinner"></div>  {/* Spinner element */}
                                </div>
                            ) : (
                                <div>
                                    <h3>Очаги поражения листьев:</h3>
                                    <Matrix data={infect}/>
                                </div>
                            )

                    )}

                </div>
            </div>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
                        {uploading ? (
                            <div className="spinner"></div> // Спиннер при загрузке
                        ) : (
                            <VideoUpload onFilesSelected={handleUpload} setUploading={setUploading} />
                        )}
                    </div>
                </div>
            )}

            {isProgressModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsProgressModalOpen(false)}>&times;</span>
                        <div className="modal-progress-wrapper">
                            <div className="progress-bar-container">
                                <ProgressBar progress={bar}/>
                            </div>
                            <button className="stop" onClick={handleStop}>Стоп</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default LK;
