import React from "react";
import './About.css'
function About() {
    return (
        <div>
            <h2>про нас</h2>

            <div className="container">
                <div className="col-1-3">
                    Наш проект — это уникальная разработка российских программистов. Наше приложение заменит утратившие к настоящему времени актуальность технологические решения и составит конкуренцию существующим, а также позволит повысить эффективность функционирования российского агропромышленного комплекса (далее - АПК) за счет внедрения возможности выявления и определения болезней растений. Применение данного решения, в свою очередь, позволит фермерским хозяйствам своевременно подобрать эффективные методы лечения и повышения иммунитета растений и снизить риск потери урожая.

                    Наш проект — это уникальная разработка российских программистов. Наше приложение заменит утратившие к настоящему времени актуальность технологические решения и составит конкуренцию существующим, а также позволит повысить эффективность функционирования российского агропромышленного комплекса (далее - АПК) за счет внедрения возможности выявления и определения болезней растений. Применение данного решения, в свою очередь, позволит фермерским хозяйствам своевременно подобрать эффективные методы лечения и повышения иммунитета растений и снизить риск потери урожая.

                    Наш проект — это уникальная разработка российских программистов. Наше приложение заменит утратившие к настоящему времени актуальность технологические решения и составит конкуренцию существующим, а также позволит повысить эффективность функционирования российского агропромышленного комплекса (далее - АПК) за счет внедрения возможности выявления и определения болезней растений. Применение данного решения, в свою очередь, позволит фермерским хозяйствам своевременно подобрать эффективные методы лечения и повышения иммунитета растений и снизить риск потери урожая.


                </div>
                <div className="col-2-3">

                </div>
                <div className="col-1-2">

                </div>
                <div className="col-1-2">

                </div>
                <div className="col-1-4">

                </div>
                <div className="col-1-4">

                </div>
                <div className="col-1-2">

                </div>
            </div>
        </div>
)
    ;
}

export default About