import axios from 'axios';
import {URL_web} from "../Modules/URL";

const login = async (email, password) => {
    try {
        const response = await axios.post(URL_web() + 'auth/login', {
            email,
            password
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        handleError(error);
        throw error;
    }
};

const checkToken = async (reset_token) => {
    try {
        const response = await axios.post(URL_web() + 'token/check_token', {
            reset_token
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        handleError(error);
        throw error;
    }
};

const resetPassword = async (reset_token, password) => {
    try {
        const response = await axios.post(URL_web() + 'auth/reset', {
            reset_token,
            password
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        handleError(error);
        throw error;
    }
};

const signup = async (email, password, company) => {
    try {
        const response = await axios.post(URL_web() + 'auth/signup', {
            email,
            password,
            company,
            confirmation: false
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        handleError(error);
        throw error;
    }
};

const confirmEmail = async (confirm_token) => {
    try {
        const response = await axios.post(URL_web() + 'auth/confirm', {
            confirm_token,
            emailing: false
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        handleError(error);
        throw error;
    }
};


const confirmAccess = async (access_token) => {
    try {
        const response = await axios.post(URL_web() + 'auth/user', {
            access_token
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        handleError(error);
        throw error;
    }
};

const refreshToken = async (refreshToken) => {
    try {
        const response = await axios.post(
            URL_web() + 'token/refresh',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        handleError(error);
        throw error;
    }
};

const forgotPass = async (formData) => {
    try {
        const response = await axios.post(URL_web() + 'auth/forgot', {
            email: formData.email,
            confirmation: false

        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.status === 200) {
            return response.data;
            // Handle successful response

        }
    } catch (error) {
        handleError(error);
        throw error;
    }
}

const handleError = (error) => {
    /*if (error.response) {
        switch (error.response.status) {
            case 500:
                console.error('InternalServerError');
                break;
            case 422:
                console.error('Decode Error');
                break;
            case 400:
                console.error('SomeRequestArgumentsMissing');
                break;
            case 401:
                console.error('Invalid username or password');
                break;
            case 403:
                console.error('Invalid token');
                break;
            default:
                console.error('Error:', error);
        }
    } else {
        console.error('Error:', error);
    }*/
};
export {login, checkToken, resetPassword, signup, confirmEmail, confirmAccess, refreshToken, forgotPass};

