// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* progressbar.css */
.progress-bar-container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative; /* For positioning the numbers */
    padding: 0; /* Remove padding as needed */
}

.progress-bar-background {
    flex: 1 1; /* Make progress bar fill available space */
    height: 32px;
    background-color: #c7c7c7;
    border-radius: 4px;
    margin-right: 10px; /* Space between bar and number */
}

.progress-bar-fill {
    height: 100%;
    border-radius: 4px;
    transition: width 0.5s ease-in-out;
}

.progress-bar-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-weight: bold;
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/Modules/ProgressBar.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB,EAAE,gCAAgC;IACpD,UAAU,EAAE,6BAA6B;AAC7C;;AAEA;IACI,SAAO,EAAE,2CAA2C;IACpD,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB,EAAE,iCAAiC;AACzD;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,iBAAiB;IACjB,WAAW;AACf","sourcesContent":["/* progressbar.css */\n.progress-bar-container {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    position: relative; /* For positioning the numbers */\n    padding: 0; /* Remove padding as needed */\n}\n\n.progress-bar-background {\n    flex: 1; /* Make progress bar fill available space */\n    height: 32px;\n    background-color: #c7c7c7;\n    border-radius: 4px;\n    margin-right: 10px; /* Space between bar and number */\n}\n\n.progress-bar-fill {\n    height: 100%;\n    border-radius: 4px;\n    transition: width 0.5s ease-in-out;\n}\n\n.progress-bar-text {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1;\n    font-weight: bold;\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
