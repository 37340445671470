// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* progressbar.css */
.progress-bar-container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
}

.progress-bar-background {
    flex: 1 1;
    height: 32px;
    background-color: #c7c7c7;
    border-radius: 4px;
    margin-right: 10px;
}

.progress-bar-fill {
    height: 100%;
    border-radius: 4px;
    transition: width 0.5s ease-in-out;
}

.progress-bar-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-weight: bold;
    color: #fff;
}

/* Мобильная версия */
@media (max-width: 768px) {
    .progress-bar-background {
        margin-right: 5px; /* Меньший отступ между баром и текстом */
    }

    .progress-bar-text {
        width: auto;
    }
}

@media (max-width: 480px) {
    .progress-bar-background {
        margin-right: 3px;
    }

    .progress-bar-text {
        font-size: 12px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Modules/ProgressBar.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,SAAO;IACP,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,iBAAiB;IACjB,WAAW;AACf;;AAEA,qBAAqB;AACrB;IACI;QACI,iBAAiB,EAAE,yCAAyC;IAChE;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":["/* progressbar.css */\r\n.progress-bar-container {\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    position: relative;\r\n    padding: 0;\r\n}\r\n\r\n.progress-bar-background {\r\n    flex: 1;\r\n    height: 32px;\r\n    background-color: #c7c7c7;\r\n    border-radius: 4px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.progress-bar-fill {\r\n    height: 100%;\r\n    border-radius: 4px;\r\n    transition: width 0.5s ease-in-out;\r\n}\r\n\r\n.progress-bar-text {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    z-index: 1;\r\n    font-weight: bold;\r\n    color: #fff;\r\n}\r\n\r\n/* Мобильная версия */\r\n@media (max-width: 768px) {\r\n    .progress-bar-background {\r\n        margin-right: 5px; /* Меньший отступ между баром и текстом */\r\n    }\r\n\r\n    .progress-bar-text {\r\n        width: auto;\r\n    }\r\n}\r\n\r\n@media (max-width: 480px) {\r\n    .progress-bar-background {\r\n        margin-right: 3px;\r\n    }\r\n\r\n    .progress-bar-text {\r\n        font-size: 12px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
