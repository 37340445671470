import React from 'react';
import ReactPlayer from 'react-player';

const VideoPreview = ({url, homepage}) => {
    const handleError = (e) => {
        if (process.env.REACT_APP_NODE_ENV === 'development')
            console.error("Ошибка загрузки плеера: ", e);
        throw e
    };

    const isGif = url.endsWith('.gif');

    return (
        <div className='player-wrapper' style={{position: 'relative', paddingTop: '56.25%'}}>
            {isGif ? (
                <img
                    src={url}
                    alt="GIF preview"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'  // Заполняет весь экран
                    }}
                />
            ) : (
                <ReactPlayer
                    url={url}
                    width='100%'
                    height='100%'
                    style={{position: 'absolute', top: 0, left: 0}}
                    controls={!homepage}  // Показывать элементы управления только если homepage = false
                    playing={true}   // Автоматическое воспроизведение если homepage = false
                    loop={true}      // Повторное воспроизведение если homepage = false
                    muted={homepage}      // Без звука если homepage = true
                    onError={handleError} // Добавление обработки ошибки
                />
            )}

        </div>
    );
};

export default VideoPreview;
