import React from "react";
import { Link } from "react-router-dom";
import logo from "../pictures/logo.png";
import Kallosus_small from "../pictures/Kallosus_small.svg";

function Footer() {
    return (
        <footer style={footerStyle}>
            <div style={footerContentStyle}>
                <div style={logoSectionStyle}>
                    <img src={Kallosus_small} alt="Kallosus" style={logoStyle} />
                    <img src={logo} alt="Фонд содействия инновациям" style={logoStyle} />
                </div>

                <div style={sectionStyle}>
                    <h4 style={sectionHeaderStyle}>Информация</h4>
                    <ul style={linkListColumnStyle}>
                        <li><Link to="/documents" style={infoLinkStyle}>Документы</Link></li>
                        <li><Link to="/instructions" style={infoLinkStyle}>Инструкция</Link></li>
                        <li><Link to="/faq" style={infoLinkStyle}>FAQ</Link></li>
                        <li><Link to="/offer" style={infoLinkStyle}>Оферта</Link></li>
                    </ul>
                </div>

                <div style={sectionStyle}>
                    <h4 style={sectionHeaderStyle}>Контакты</h4>
                    <p><strong>Обращайтесь по любым вопросам</strong></p>
                    <p>Почта: <a href="mailto:admin@kallosus.ru" style={linkStyle}>admin@kallosus.ru</a></p>
                    <p>Телефон: <a href="tel:+79939134660" style={linkStyle}>+7(995)155-46-60</a></p>
                    <p>Адрес: 115432, город Москва, пр-кт Андропова, д. 10</p>
                </div>
            </div>

            <div style={footerBottomStyle}>
                <p>&copy; 2024 Kallosus</p>
            </div>
        </footer>
    );
}

const footerStyle = {
    backgroundColor: '#f5f5f5',
    padding: '0 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const footerContentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1200px',
    flexWrap: 'wrap', // Allow wrapping for smaller screens
};

const sectionStyle = {
    flex: 1,
    padding: '10px',
    minWidth: '250px', // Ensure sections have a minimum width on small screens
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center content horizontally
    textAlign: 'center', // Center text inside section
    justifyContent: 'center', // Center content vertically
    height: 'auto', // Allow height to adjust based on content
};

const sectionHeaderStyle = {
    fontSize: '24px',
    marginTop: '10px',
    marginBottom: '10px',
};

const infoLinkStyle = {
    color: '#000',
    textDecoration: 'none',
    margin: '5px 0',
    fontSize: '24px',
};

const linkListColumnStyle = {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center list items horizontally
};

const linkStyle = {
    color: '#000',
    textDecoration: 'none',
    fontSize: '18px',
    margin: '5px 0',
    display: 'block',
};

const logoSectionStyle = {
    display: 'flex',
    flexDirection: 'column', // Align logos vertically
    alignItems: 'center',    // Center logos horizontally
    justifyContent: 'center', // Center logos vertically
    marginTop: '50px',
    marginBottom: '20px',
    width: 'auto', // Auto width for larger screens
    textAlign: 'center', // Center logos horizontally
};

const logoStyle = {
    maxWidth: '150px',
    margin: '10px', // Space between logos
};

const footerBottomStyle = {
    textAlign: 'center',
    fontSize: '14px',
    color: '#888',
};

// Media queries for mobile responsiveness
const mediaQueries = {
    '@media (max-width: 768px)': {
        footerStyle: {
            padding: '0 20px',
        },
        footerContentStyle: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        sectionStyle: {
            textAlign: 'center',
            justifyContent: 'center',
            height: 'auto', // Allow height to adjust based on content
        },
        infoLinkStyle: {
            fontSize: '18px',
        },
        linkStyle: {
            fontSize: '16px',
        },
        logoSectionStyle: {
            width: '100%', // Ensure full width for mobile
        },
        logoStyle: {
            maxWidth: '120px',
        },
    },
    '@media (max-width: 480px)': {
        sectionHeaderStyle: {
            fontSize: '20px',
        },
        infoLinkStyle: {
            fontSize: '16px',
        },
        linkStyle: {
            fontSize: '14px',
        },
        logoStyle: {
            maxWidth: '100px',
        },
    },
};

export default Footer;
