import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import cloud from "../pictures/cloud.png";
import './VideoUpload.css';

const VideoUpload = ({ onFilesSelected, setUploading }) => {
    const [files, setFiles] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        const newFiles = acceptedFiles.filter(file =>
            !files.some(prevFile => prevFile.name === file.name)
        );

        const updatedFiles = [
            ...files,
            ...newFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        ].slice(0, 5); // Ограничение на максимум 5 файлов

        setFiles(updatedFiles);
    }, [files]);

    const removeFile = (fileName) => {
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter(file => file.name !== fileName);
            updatedFiles.forEach(file => URL.revokeObjectURL(file.preview)); // Освобождение ресурсов
            return updatedFiles;
        });
    };

    const handleUpload = () => {
        setUploading(true);  // Устанавливаем состояние загрузки

        onFilesSelected(files);  // Передаем файлы в родительский компонент
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {'video/*': ['.mp4', '.mkv', '.avi']},
        multiple: true
    });

    useEffect(() => {
        // Cleanup URL objects when component unmounts
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        };
    }, [files]);

    return (
        <div className='main'>
            <div {...getRootProps({ className: 'dropzone' })} style={{ minHeight: files.length > 0 ? 'auto' : '150px' }}>
                <input {...getInputProps()} />
                {files.length === 0 && (
                    <div className="placeholder">
                        <img src={cloud} alt="Cloud" className="placeholder-image" />
                        <p>{isDragActive ? 'Перетащите видео сюда ...' : 'Перетащите видео сюда или нажмите, чтобы выбрать файлы'}</p>
                    </div>
                )}
                <div className="preview-container">
                    {files.map(file => (
                        <div key={file.name} className="preview">
                            <video src={file.preview} controls />
                            <button className="remove-button" onClick={(e) => { e.stopPropagation(); removeFile(file.name); }}>×</button>
                            <p>{file.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            {files.length > 0 && (
                <button className="upload-button" onClick={handleUpload}>Загрузить</button>
            )}
        </div>
    );
};

export default VideoUpload;
