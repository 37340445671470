import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Login from './Pages/Login';
import Logout from './Modules/Logout';
import Signup from './Pages/Signup';
import Navbar from './Nav/Navbar';
import Footer from './Nav/Footer';
import EmailConfirm from './Pages/Email_conformation';
import LK from './Pages/LK';
import GoToConfirm from './Pages/GoToConfirm';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import NotFoundPage from "./Pages/NotFoundPage";
function App() {

    return (
        <Router>
            <div>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>

                    <Route path="/login" element={<Login/>}/>
                    <Route path="/logout" element={<Logout/>}/>

                    <Route path="/forgot" element={<ForgotPassword/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/go_to_confirm" element={<GoToConfirm/>}/>
                    <Route path="/confirm"/>
                    <Route path="/lk" element={<LK/>}/>
                    <Route exact path="/confirm/:token" element={<EmailConfirm/>}/>
                    <Route exact path="/reset/:token" element={<ResetPassword/>}/>
                    <Route path="*" element={<NotFoundPage/>} />
                </Routes>

            </div>
        </Router>
    );
}

export default App;